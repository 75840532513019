import React, { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import CategoryFilter from '../../../CategoryFilter';
import PriceRangeFilter from '../../../PriceRangeFilter';
import GiftsGrid from '../../../GiftsGrid';
import { CategoriesContext, OriginalUrlContext } from '../../../../utils/context';
import { DEFAULT_CURRENCY } from '../../../../utils/currency';

import styles from './index.module.scss';
import { useSelector } from 'react-redux';

const BBCatalogWithFilters = () => {
  const products = useSelector((state) => state.productsStorage.gifts);
  const categories = useContext(CategoriesContext);
  const originalUrl = useContext(OriginalUrlContext);
  // Values should be stored globally to be able to reuse them when category changes.
  // TODO: Move it to redux store when it is ready.
  const router = useRouter();
  const initialRangePrice =
    router.query.rangePriceStart &&
    router.query.rangePriceEnd &&
    !isNaN(router.query.rangePriceStart) &&
    !isNaN(router.query.rangePriceEnd)
      ? [+router.query.rangePriceStart, +router.query.rangePriceEnd]
      : [0, 1500];
  const [priceRange, setPriceRange] = useState(initialRangePrice);
  const activeCategory = Object.values(categories).filter(
    (item) => item.url.url === originalUrl.split('?')[0],
  );
  let activeCategoryId;
  if (activeCategory.length) {
    activeCategoryId = activeCategory[0].id;
  }
  const filteredProducts = Object.values(products).filter((item) => {
    if (!item.categoryIds.includes(activeCategoryId)) {
      return false;
    }

    if (
      +item.price[DEFAULT_CURRENCY].amount < priceRange[0] ||
      +item.price[DEFAULT_CURRENCY].amount > priceRange[1]
    ) {
      return false;
    }

    return true;
  });

  const sortedProducts = filteredProducts.sort((a, b) => {
    return a.fieldBestSellerWeight - b.fieldBestSellerWeight || a.fieldWeight - b.fieldWeight;
  });

  return (
    <div className={`bb ${styles['bb-catalog-with-filters']}`}>
      <div className="container">
        <div className={styles['bb-catalog-with-filters__container']}>
          <div className={styles['bb-catalog-with-filters__menu']}>
            {categories && Object.values(categories).length && activeCategoryId && (
              <CategoryFilter categories={categories} activeCategoryId={activeCategoryId} />
            )}
            <PriceRangeFilter
              initialPriceRange={priceRange}
              onPriceRangeChange={(values) => {
                setPriceRange(values);
                window.history.pushState(
                  null,
                  null,
                  `${router.asPath.split('?')[0]}?rangePriceStart=${values[0]}&rangePriceEnd=${values[1]}`,
                );
              }}
            />
          </div>
          <GiftsGrid gifts={sortedProducts} variant="catalog" />
        </div>
      </div>
    </div>
  );
};

export default BBCatalogWithFilters;
