import React from 'react';
import PropTypes from 'prop-types';
import GiftItem from '../GiftItem';

import styles from './index.module.scss';

const GiftsGrid = ({ gifts, variant = 'default' }) => {
  const products = gifts.map((product) => (
    <GiftItem product={product} key={product.id} variant={variant} />
  ));

  return (
    <div className={`${styles['gifts-grid']} ${styles['gifts-grid--' + variant]}`}>
      <div className={styles['gifts-grid__gifts']}>
        {products.length > 0 ? (
          products
        ) : (
          <div className={styles['gifts-grid__container']}>
            <h5>There are no gifts in this price range. Please select another price range.</h5>
            <h5>Thank you.</h5>
          </div>
        )}
      </div>
    </div>
  );
};

GiftsGrid.propTypes = {
  variant: PropTypes.oneOf(['default', 'catalog']),
  gifts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      title: PropTypes.string,
      categoryIds: PropTypes.arrayOf(PropTypes.number),
      image: PropTypes.object,
      url: PropTypes.object,
      shortDescription: PropTypes.string,
    }),
  ).isRequired,
};

export default GiftsGrid;
