import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';

import styles from './index.module.scss';
import Select from '../../Select';

const CategoryFilter = ({ categories, activeCategoryId }) => {
  const [isToggled, setToggled] = useState(false);
  const onCLickHandler = () => {
    setToggled(!isToggled);
  };
  const sortedCategories = Object.values(categories).sort((a, b) => a.weight - b.weight);

  const selectCategories = sortedCategories.map((category) => ({
    key: category.id,
    href: category.url.url,
    value: category.title,
    highlightedColor: category.highlightedColor,
  }));

  return (
    <>
      <div className={styles['category-filter']}>
        <div className={styles['category-filter__wrapper']}>
          <button
            onClick={onCLickHandler}
            className={`${styles['category-filter__button']} ${isToggled ? styles['category-filter__button--collapsed'] : ''}`}
          >
            {categories[activeCategoryId].title}
            <span className={`material-icons chevron_right ${styles['category-filter__icon']}`} />
          </button>
          <ul className={`nav-filters`}>
            <button onClick={onCLickHandler} className="clear-btn">
              <span className="material-icons clear" />
            </button>
            {sortedCategories.map((category) => (
              <li
                key={category.id}
                className={`${category.id === activeCategoryId ? 'active' : ''} ${category.highlightedColor ? styles['category-filter__item--highlighted-' + category.highlightedColor] : ''}`}
              >
                <Link href={category.url.url} scroll={false}>
                  <a data-test="category-filter-link" data-analytics={'gift-category'}>
                    {category.title}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Select
        className={styles['category-filter__categories-mobile']}
        options={selectCategories}
        initialValue={activeCategoryId}
      />
    </>
  );
};

CategoryFilter.propTypes = {
  categories: PropTypes.object.isRequired,
  activeCategoryId: PropTypes.number.isRequired,
};

export default CategoryFilter;
